import React, {useEffect, useMemo, useRef, useState} from 'react';
import { Unity, useUnityContext } from "react-unity-webgl";
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    LinearProgress
} from "@mui/material";
import {useLanguage} from "./context/LanguageContext";
import config from "./config";


const UnityWrapper = ({buildNumber, userInfo, jwtData, addReward}) => {
console.log('!!!try load UnityWrapper')
    const { language, changeLanguage, getT } = useLanguage();

    const urlParams = new URLSearchParams(window.location.search);

    function getStorageItem(key, defaultValue) {
        const storedValue = localStorage.getItem(key);
        return (storedValue !== null && storedValue !== 'null' && storedValue !== undefined) ? storedValue : defaultValue;
    }

    const initialBuild = urlParams.get('build') || buildNumber;
    const initialType = urlParams.get('type') || config.REACT_APP_ENV;


    localStorage.setItem('build', initialBuild)
    localStorage.setItem('type', initialType)

    const buildType = localStorage.getItem('quality') || (urlParams.get('buildType') == 'normal' ? 'normal' : 'downscale')


    const urls = {
        'prod': config.REACT_APP_CLIENT_PROD_URL,
        'dev':  config.REACT_APP_CLIENT_DEV_URL,
        'prod_cheats':  config.REACT_APP_CLIENT_PROD_CHEATS_URL
    }

    let url = urls.hasOwnProperty(initialType) ? urls[initialType] : urls['dev']
    url += initialBuild + "/" + buildType


    const unityConfig = useMemo(() => ({
        dataUrl: url + "/Build/" + buildType + ".data",
        frameworkUrl: url + "/Build/" + buildType + ".framework.js",
        codeUrl: url + "/Build/" + buildType + ".wasm",
        loaderUrl: url + "/Build/" + buildType + ".loader.js",
        streamingAssetsUrl: url + "/StreamingAssets",
        companyName: "BelkaGames",
        productName: "CM",
        productVersion: "1.0",
    }), [url, buildType]);

    const { unityProvider, loadingProgression, isLoaded, sendMessage, addEventListener, UNSAFE__unityInstance } = useUnityContext(unityConfig)

    const sendDelayed = () => {
        //const a = userInfo.field.val
        sendMessage("PWAJSHelper", "OnAddRegistrationReward");
    }

    useEffect(() => {
            window.unityInstance = UNSAFE__unityInstance
            window.postAnalyticsEvent("clientLoadStart", window.userID, "", false);
            if(urlParams.get("utm_notification") !== null)
                window.postAnalyticsEvent("notificationGameEnter", window.userID, "#is#notification_id="+urlParams.get("utm_notification"), false);

            const handleNotifyGameLoadComplete = (event) => {
                console.log('===== handleNotifyGameLoadComplete');

                if(userInfo.data != undefined) {
                    if(userInfo.data.new_user == true && (localStorage.getItem("registration") == "reward" ||
                        (userInfo.data != undefined &&
                            userInfo.data.reward == false)) && jwtData != null && jwtData.hasOwnProperty('sub')) {
                        sendMessage("PWAJSHelper", "OnAddRegistrationReward");
                    }
                }


                window.__TEST__clientLoaded = 1
                window.postAnalyticsEvent("clientLoadComplete", window.userID, "", false);
            };


            const handleCustomUnityMessage = (event) => {
                console.log(event.detail);
            };


            const handleNotifyRewardHasBeenGiven = (event) => {
                console.log('handleNotifyRewardHasBeenGiven');

                if( (localStorage.getItem("registration") == "reward" &&
                    jwtData!= null && jwtData?.sub != undefined) || userInfo.data.reward == false ) {
                    localStorage.setItem("registration","complete")
                    addReward({xsolla_id: jwtData?.sub, user_id: window.gameParams.user_id})
                }

            };

            window.addEventListener('onNotifyGameLoadComplete', handleNotifyGameLoadComplete);

            window.addEventListener('sendCustomUnityMessage', handleCustomUnityMessage);
            window.addEventListener('onNotifyRewardHasBeenGiven', handleNotifyRewardHasBeenGiven);

            return () => {
                window.removeEventListener('sendCustomUnityMessage', handleCustomUnityMessage);
                window.removeEventListener('onNotifyRewardHasBeenGiven', handleNotifyRewardHasBeenGiven);

                window.removeEventListener('onNotifyGameLoadComplete', handleNotifyGameLoadComplete);
            }
        },
        [UNSAFE__unityInstance]
    );

    const prevLanguageRef = useRef(language);

    useEffect(() => {
        if (isLoaded && language != undefined && language != '' && language !== prevLanguageRef.current) {
            changeUnityLanguage(language.toUpperCase());
            prevLanguageRef.current = language;
        }
    }, [language, isLoaded]);


    const changeUnityLanguage = (language) => {
        console.log('===== changeUnityLanguage (request to unity)')
        console.log('===== ' + language.toLowerCase())
        sendMessage("PWAJSHelper", "OnLocalizationChanged",language.toLowerCase());
        //window.unityInstance.SendMessage("PWAJSHelper", "OnLocalizationChanged",language.toUpperCase());
    }


    const lastSentProgress = useRef(0);
    const startTime = useRef(Date.now());
    useEffect(() => {

        if (loadingProgression >= lastSentProgress.current + 0.1 || loadingProgression === 1) {
            const elapsedTime = Date.now() - startTime.current;
            window.postAnalyticsEvent(
                "gameFileLoadProgress",
                window.gameParams.user_id,
                `#is#progress_percentage=${Math.floor(loadingProgression * 100)}#is#elapsedTime=${elapsedTime}`,
                false
            );
            lastSentProgress.current = Math.floor(loadingProgression * 10) / 10;
        }

        window.__TEST__loadingProgression = loadingProgression;
    }, [loadingProgression])

    //
    // useEffect(() => {
    //     const handleLanguageChanged = (event) => {
    //         changeUnityLanguage(event.detail.toUpperCase())
    //     }
    //     window.addEventListener('onLanguageChanged', handleLanguageChanged);//askNotificationPermission
    //     return () => {
    //         window.removeEventListener('onLanguageChanged', handleLanguageChanged);
    //     };
    // }, []);

    return (
        <>
            {!isLoaded && (
                <div className="gameWrapper">
                    <div className={"startPlay"}>
                        <div className={"loading"}>
                            {getT('loading')} {Math.round(loadingProgression * 100)}%
                            <LinearProgress sx={{pt:1,mt:1}} variant="determinate" thickness={4} value={loadingProgression * 100} />
                        </div>
                    </div>
                </div>
            )}

            <Unity
                matchWebGLToCanvasSize={true}
                unityProvider={unityProvider}
                style={{ visibility: isLoaded ? "visible" : "hidden" }}
            />


        </>
    );

};

export default UnityWrapper;