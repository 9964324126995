import {Button, Link} from "@mui/material";

export const Footer = ({language, getT, userId, buildType}) => {
    return <footer style={{ }}>
        <div>
            © Belka Games, 2024<br />
            Samfinaco LLC<br />
            1100 Page MillRoad Palo Alto, CA 94304
        </div>
        <div>
            <Button
                onClick={() => {
                    window.postAnalyticsEvent("supportButton", window.userID, "", false);
                }}
                href={"https://support.belka-games.com/hc/en-us/categories/360002081597-Clockmaker"} target={"_blank"} variant="contained" color="warning" sx={{m:1}}>{getT("support")}</Button>
            <Button href={"https://belka-games.com/"+language.toLowerCase()+"/privacy"} target={"_blank"} variant="contained" color="warning" sx={{m:1}}>{getT("privacy")}</Button>
            <Button href={"https://belka-games.com/"+language.toLowerCase()+"/terms-of-service"} target={"_blank"} variant="contained" color="warning" sx={{m:1}}>{getT("terms")}</Button>

        </div>
    </footer>
}