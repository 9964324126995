export const domainAlias = {
    'localhost': 'test',
    'pwa-cm-fe.stage.belka-games.com': 'dev',
    'pwa-cm-fe.ita.belka-games.com': 'dev',
    'clockmaker-game.com': 'prod'
};

export function getDomainAlias() {
    let hostname = window.location.hostname;
    return domainAlias[hostname] || 'local';
}

// Определяем окружение на основе домена
const env = getDomainAlias();

// Импортируем нужную конфигурацию
const config = require(`./configs.${env}.js`).configs;
window.__TEST__TEMP = config
export default config;