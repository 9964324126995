import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react"
import config from "../../config";

const api = createApi({
    baseQuery: fetchBaseQuery({
        baseUrl: config.REACT_APP_API_URL,
        prepareHeaders: (headers) => {
            return headers
        }
    }),
    endpoints: () => ({})
})

export default api
