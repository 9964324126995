import React, { useState } from 'react';

const StartGame = ({setPlay, jwtData}) => {
    return <div className={"startPlay"}>
        <div className="playBtn-wrapper">
            <div className={"playBtn"} onClick={() => {
                const jwtData = window.getParsedXsollaJwt()
                if(jwtData == null || !jwtData.hasOwnProperty("email")) {
                    window.showXsollaLogin()
                } else {
                    setPlay(true)}
            }

            }></div>
        </div>
    </div>
}

export default StartGame