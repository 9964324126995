import React, { useState } from 'react';

import AppStoreImg from "../img/app-store-badge.png";
import GoogleStoreImg from "../img/google-play-badge.png";
import AmazonStoreImg from "../img/amazon-store-badge.png";
import {useLanguage} from "../context/LanguageContext";

const WebglUnsupported = () => {
    const { language, changeLanguage, getT } = useLanguage();

    return <div id="blocker-wrapper"><div id="blocker">
        <div id="blocker-label">
            <div>
                {getT('game_start_error')}

            </div>
        </div>
        <div id="store-links-container">
            <a href="https://apps.apple.com/us/app/clockmaker-match3-puzzle/id929195587" className="store-link">
                <img src={AppStoreImg}
                     className="store-link-image" />
            </a>
            <a href="https://play.google.com/store/apps/details?id=com.belkatechnologies.clockmaker&amp;hl=en"
               className="store-link">
                <img src={GoogleStoreImg}
                     className="store-link-image" />
            </a>
            <a href="https://www.amazon.com/Samfinaco-Limited-Clockmaker-Amazing-Match/dp/B00YXZ4L2Q/ref=sr_1_1?dchild=1&amp;keywords=clockmaker&amp;qid=1631532787&amp;sr=8-1"
               className="store-link">
                <img src={AmazonStoreImg}
                     className="store-link-image" />
            </a>
        </div>
    </div></div>
}
export default WebglUnsupported