import {configureStore, createSlice, isRejectedWithValue} from "@reduxjs/toolkit"
import api from "./api/api"
import root from "./reducers/index"


import * as Sentry from "@sentry/react";

Sentry.init({
    dsn: "https://bd4f73a048b948c882e19e6569e0a21e@cross-sentry.belka-games.com/37",
    environment: window.location.hostname!='localhost'?'production':'local',
    tracesSampleRate: 1.0,
    release: ''
});

window.onerror = function (message, source, lineno, colno, error) {
    console.error('Global onerror caught:', message, source, lineno, colno, error);
    Sentry.captureException(error || new Error(message));
};

window.onunhandledrejection = function (event) {
    console.error('Unhandled promise rejection:', event.reason);
    Sentry.captureException(event.reason || new Error('Unhandled rejection'));
};

const myLoggingMiddleware = storeAPI => next => action => {
    if (isRejectedWithValue(action)) {
        const jsonError = JSON.stringify(action.payload.data);
        if (action.meta.baseQueryMeta.request.body != null) {
            action.meta.baseQueryMeta.request.body.getReader().read().then(response => {
                const jsonRequest = JSON.stringify(response.value);
                Sentry.captureMessage(
                    `Error during request \n${jsonError}\n${jsonRequest}`,
                    "Error"
                )
            });
        } else {
            if (action.payload.status == 403 || action.payload.status == 401) {
                Sentry.captureMessage(
                    `Server error ${action.payload.status}  ${jsonError}`,
                    "Info"
                )
            } else if (action.payload.status !== 404) {
                Sentry.captureMessage(
                    `Response error ${action.payload.status} ${jsonError}`,
                    "Error"
                )
            }
        }
    }
    return next(action)
}


export const store = configureStore({
    reducer: {
        [api.reducerPath]: api.reducer,
        data: root,
    },
    middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(api.middleware).concat(myLoggingMiddleware)

})

