import api from "./api"

export const requestsApi = api.injectEndpoints({
    endpoints: (build) => ({
        saveToken: build.mutation({
            query: (body = {}) => ({
                url: `token`,
                method: "POST",
                body: body
            }),
        }),
        addReward: build.mutation({
            query: (body = {}) => ({
                url: `add_reward`,
                method: "POST",
                body: body
            }),
        }),
        setOld: build.mutation({
            query: (body) => ({
                url: `set_old?xsolla_id=`+body.xsolla_id+'&new_user='+body.new_user,
                method: "POST",
            }),
        }),
        deleteInfo: build.mutation({
            query: (body) => ({
                url: `delete_test?user_id=`+body.user_id,
                method: "POST",
            }),
        }),

        updateUser: build.mutation({
            query: (body = {}) => {
                let url = 'get_user?'

                if(body.hasOwnProperty('xsolla_id')&&body?.xsolla_id!=null)
                    url+='xsollaId='+body.xsolla_id+'&'

                if(body.hasOwnProperty('generated_id'))
                    url+='generatedId='+body.generated_id+'&'

                url+='pwaInstalled='+body.pwa_installed+'&'
                url+='notificationEnabled='+body.notification_enabled+'&'

                return {
                    url: url,
                    method: "POST",
                    body: body
                }
            },
        }),
        getUser: build.query({
            query: (body) => {

                let url = 'get_user?'

                if(body.hasOwnProperty('xsolla_id')&&body?.xsolla_id!=null)
                    url+='xsollaId='+body.xsolla_id+'&'

                if(body.hasOwnProperty('generated_id'))
                    url+='generatedId='+body.generated_id+'&'

                url+='pwaInstalled='+body.pwa_installed+'&'
                url+='notificationEnabled='+body.notification_enabled+'&'
                url+='email='+body.email+'&'

                return {
                    url: url
                }
            },
        }),
        getNotifications: build.query({
            query: (body) => {

                let url = 'messages?browser='+body.browser+'&'

                if(body.hasOwnProperty('xsolla_id'))
                    url+='xsollaId='+body.xsolla_id+'&'

                if(body.hasOwnProperty('generated_id'))
                    url+='userId='+body.generated_id+'&'

                return {
                    url: url
                }
            },
            providesTags: ['Notifications'],
            keepUnusedDataFor: 0

        }),
        testSend: build.mutation({
            query: (body = {}) => ({
                url: `send`,
                method: "POST",
                body: body
            }),
            invalidatesTags: ['Notifications']

        }),
        checkToken: build.mutation({
            query: (body = {}) => ({
                url: `check_token`,
                method: "POST",
                body: body
            }),
        }),
        cancelNotification: build.mutation({
            query: (body = {}) => ({
                url: `cancel_notifications`,
                method: "POST",
                body: body
            }),
        }),
        sendMsg: build.mutation({
            query: (body = {}) => ({
                url: `send_message`,
                method: "POST",
                body: body
            }),
        }),
    }),
})

export const {
    useSaveTokenMutation,
    useDeleteInfoMutation,
    useSetOldMutation,
    useAddRewardMutation,
    useTestSendMutation,
    useUpdateUserMutation,
    useGetUserQuery,
    useGetNotificationsQuery,
    useCancelNotificationMutation,
    useCheckTokenMutation,
    useSendMsgMutation,
} = requestsApi