export const configs = {
    REACT_APP_API_URL:"https://pwa-cm-be.ita.belka-games.com/",
    REACT_APP_ENV:"dev",
    REACT_APP_VERSION_URL:"https://cm-staging-webgl.belka-games.com/static/mcmu-standalone-dev",
    PUBLIC_URL:"/play",
    REACT_APP_CLIENT_PROD_URL:"https://mcmustatic.b-cdn.net/mcmu-standalone-prod/Build-",
    REACT_APP_CLIENT_DEV_URL:"https://cm-staging-webgl.belka-games.com/static/mcmu-standalone-dev/Build-",
    REACT_APP_CLIENT_PROD_CHEATS_URL:"https://mcmustatic.b-cdn.net/mcmu-standalone-prod-cheats/Build-",
    REACT_APP_DOMAIN:"https://pwa-cm-fe.ita.belka-games.com/"

}